import { gql } from '@apollo/client'

import { AUTHENTICATABLE_FIELDS, CREDENTIAL_KEYS } from 'shared/queries'

import type { Authenticatable, Credential } from 'shared/queries'

export type AdminCredential = Credential & {
  domain: string
  provider: string
}

export const ADMIN_CREDENTIAL_KEYS: (keyof AdminCredential)[] = [
  ...CREDENTIAL_KEYS,
  'domain',
  'provider',
]

export const ADMIN_CREDENTIAL_FIELDS = gql`
  fragment AdminCredentialFields on AdminCredential {
    ${ADMIN_CREDENTIAL_KEYS.join('\n')}
  }
`

export type AdminConfirmVars = {
  confirmationToken: string
}

export type AdminConfirmData = {
  adminConfirmRegistrationWithToken: {
    authenticatable: Authenticatable
    credentials: AdminCredential
  }
}

export const ADMIN_CONFIRM_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  ${ADMIN_CREDENTIAL_FIELDS}
  mutation AdminConfirmRegistrationWithToken(
    $confirmationToken: String!
  ) {
    adminConfirmRegistrationWithToken(
      confirmationToken: $confirmationToken
    ) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        ...AdminCredentialFields
      }
    }
  }
`

export type AdminLoginVars = {
  domain: string
  email: string
  password: string
  otp?: string
}

export type AdminLoginData = {
  adminLogin: {
    authenticatable: Authenticatable
    credentials: AdminCredential
  }
}

export const ADMIN_LOGIN_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  ${ADMIN_CREDENTIAL_FIELDS}
  mutation AdminLogin(
    $domain: String!
    $email: String!
    $password: String!
    $otp: String
  ) {
    adminLogin(
      domain: $domain
      email: $email
      password: $password
      otp: $otp
    ) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        ...AdminCredentialFields
      }
    }
  }
`

export type AdminLogoutVars = Record<string, never>

export type AdminLogoutData = {
  adminLogout: {
    authenticatable: Authenticatable
  }
}

export const ADMIN_LOGOUT_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  mutation AdminLogout {
    adminLogout {
      authenticatable {
        ...AuthenticatableFields
      }
    }
  }
`

export type AdminPasswordResetVars = {
  domain: string
  email: string
}

export type AdminPasswordResetData = {
  adminSendPasswordResetWithToken: string
}

export const ADMIN_PASSWORD_RESET_MUTATION = gql`
  mutation AdminPasswordReset(
    $domain: String!
    $email: String!
  ) {
    adminSendPasswordResetWithToken(
      domain: $domain
      email: $email
    )
  }
`

export type AdminPasswordUpdateVars = {
  password: string
  passwordConfirmation: string
  resetPasswordToken: string
}

export type AdminPasswordUpdateData = {
  adminUpdatePasswordWithToken: {
    authenticatable: Authenticatable
    credentials: AdminCredential
  }
}

export const ADMIN_PASSWORD_UPDATE_MUTATION = gql`
  ${AUTHENTICATABLE_FIELDS}
  ${ADMIN_CREDENTIAL_FIELDS}
  mutation AdminUpdatePassword(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    adminUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      authenticatable {
        ...AuthenticatableFields
      }
      credentials {
        ...AdminCredentialFields
      }
    }
  }
`

export type AdminUnlockAccessVars = {
  unlockToken: string
}

export type AdminUnlockAccessData = {
  adminUnlockAccessWithToken: string
}

export const ADMIN_UNLOCK_ACCESS_MUTATION = gql`
  mutation AdminUnlockAccess(
    $unlockToken: String!
  ) {
    adminUnlockAccessWithToken(
      unlockToken: $unlockToken
    )
  }
`
