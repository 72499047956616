exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-articulos-index-tsx": () => import("./../../../src/pages/articulos/index.tsx" /* webpackChunkName: "component---src-pages-articulos-index-tsx" */),
  "component---src-pages-articulos-posts-contentful-blog-post-slug-tsx": () => import("./../../../src/pages/articulos/posts/{contentfulBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-articulos-posts-contentful-blog-post-slug-tsx" */),
  "component---src-pages-auth-confirm-tsx": () => import("./../../../src/pages/auth/confirm.tsx" /* webpackChunkName: "component---src-pages-auth-confirm-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-password-reset-tsx": () => import("./../../../src/pages/auth/password-reset.tsx" /* webpackChunkName: "component---src-pages-auth-password-reset-tsx" */),
  "component---src-pages-auth-password-update-tsx": () => import("./../../../src/pages/auth/password-update.tsx" /* webpackChunkName: "component---src-pages-auth-password-update-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

