import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import fetch from 'isomorphic-fetch'

import { ADMIN_CREDENTIAL_KEYS } from '../queries/auth'
import { clearCredential, getCredential, setCredential } from '../services/auth'
import { clearManagedDomain, getManagedDomain } from '../services/managed_domain'

import type { AdminCredential } from '../queries/auth'
import type { Operation } from '@apollo/client'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        admins: relayStylePagination(),
        users: relayStylePagination(['filter']),
        verifications: relayStylePagination(['filter']),
        dynamicConfigs: relayStylePagination(),
        userNotifications: relayStylePagination(['filter', 'state']),
      },
    },
  },
})

const isValidCredential = (credentials: AdminCredential) => {
  if (typeof credentials !== 'object') {
    return false
  }

  return ADMIN_CREDENTIAL_KEYS.every((key) => credentials[key])
}

const updateCredentialIfValid = (operation: Operation) => {
  const { response: { headers } } = operation.getContext()

  if (!headers) {
    return
  }

  const credentials = getCredential()
  const newCredentials = {
    accessToken: headers.get('access-token') || credentials?.accessToken,
    client: headers.get('client') || credentials?.client,
    domain: headers.get('domain') || credentials?.domain,
    expiry: headers.get('expiry') || credentials?.expiry,
    provider: headers.get('provider') || credentials?.provider,
    tokenType: headers.get('token-type') || credentials?.tokenType,
    uid: headers.get('uid') || credentials?.uid,
  }

  if (!isValidCredential(newCredentials)) {
    return
  }

  setCredential(newCredentials)
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const credentials = getCredential()
  const now = new Date().getTime() / 1000

  if (credentials && isValidCredential(credentials) && credentials.expiry > now) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'access-token': credentials.accessToken,
        'client': credentials.client,
        'domain': credentials.domain,
        'expiry': credentials.expiry,
        'provider': credentials.provider,
        'token-type': credentials.tokenType,
        'uid': credentials.uid,
        'managed-domain': getManagedDomain() || credentials.domain,
      },
    }))
  }

  return forward(operation).map((value) => {
    updateCredentialIfValid(operation)
    return value
  })
})

const errorHandlerMiddleware = onError(({ graphQLErrors, operation }) => {
  // TODO: implement front-end error alerts
  if (graphQLErrors?.find((error) => error.extensions?.code === 'AUTHENTICATION_ERROR')) {
    clearCredential()
    clearManagedDomain()
    window.location.href = '/auth/login/'
    return
  }
  updateCredentialIfValid(operation)
})

const httpLink = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_URI,
  fetch,
})

const client = new ApolloClient({
  cache,
  link: from([authMiddleware, errorHandlerMiddleware, httpLink]),
})

export default client
