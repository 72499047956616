import type { AdminCredential } from '../queries/auth'

const adminCredentialKey = 'adminCredential'

export const getCredential = (): AdminCredential | null => {
  const data = typeof window !== 'undefined'
    ? window.localStorage.getItem(adminCredentialKey)
    : null

  return data ? JSON.parse(data) : null
}

export const setCredential = (credentials: AdminCredential | null): void =>
  credentials !== null
    ? window.localStorage.setItem(adminCredentialKey, JSON.stringify(credentials))
    : window.localStorage.removeItem(adminCredentialKey)

export const clearCredential = () => setCredential(null)

export const isLoggedIn = (): boolean => getCredential() !== null
